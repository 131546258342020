<template>
	<validation-observer ref="refForm">
		<b-form
			@submit.prevent="onSubmit"
			@reset.prevent="resetForm"
		>
			<b-row>
				<b-col md="12">
					<b-card
						footer-tag="footer"
						class="mb-0"
					>
						<b-row>
							<b-col cols="12">
								<b-card no-body>
									<b-card-title class="text-primary">ÍNDICES PRINCIPALES</b-card-title>
									<b-row>
										<b-col
											cols="12"
											md="3"
											class="mb-2"
										>
											<label for="billingLevel">
												<strong>SEGURIDAD</strong>
											</label>
											<validation-provider
												#default="{ errors }"
												rules="required"
												name="Seguridad"
											>
												<b-form-input
													id="billingLevel"
													v-model="formData.security"
													placeholder="-"
													:state="errors.length > 0 ? false : null"
													trim
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-col>
										<b-col
											cols="12"
											md="3"
											class="mb-2"
										>
											<label for="billingLevel">
												<strong>PRODUCTIVIDAD</strong>
											</label>
											<validation-provider
												#default="{ errors }"
												rules="required"
												name="Productividad"
											>
												<b-form-input
													id="billingLevel"
													v-model="formData.productivity"
													placeholder="-"
													:state="errors.length > 0 ? false : null"
													trim
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-col>
									</b-row>
								</b-card>
							</b-col>
							<b-col cols="12">
								<b-card no-body>
									<b-card-title class="text-primary">ÍNDICES DE CALIBRACIÓN</b-card-title>
									<b-row>
										<b-col
											cols="12"
											md="3"
											class="mb-2"
										>
											<label for="billingLevel">
												<strong>RIESGO LABORAL</strong>
											</label>
											<validation-provider
												#default="{ errors }"
												:rules="isRequired ? 'required' : ''"
												name="Riesgo Laboral"
											>
												<b-form-input
													id="billingLevel"
													v-model="formData.labor_risk"
													placeholder="-"
													:state="errors.length > 0 ? false : null"
													trim
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-col>
										<b-col
											cols="12"
											md="3"
											class="mb-2"
										>
											<label for="billingLevel">
												<strong>COLPA</strong>
											</label>
											<validation-provider
												#default="{ errors }"
												:rules="isRequired ? 'required' : ''"
												name="Colpa"
											>
												<b-form-input
													id="billingLevel"
													v-model="formData.colpa"
													placeholder="-"
													:state="errors.length > 0 ? false : null"
													trim
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-col>
										<b-col
											cols="12"
											md="3"
											class="mb-2"
										>
											<label for="billingLevel">
												<strong>RIESGO FINANCIERO</strong>
											</label>
											<validation-provider
												#default="{ errors }"
												:rules="isRequired ? 'required' : ''"
												name="Riesgo Financiero"
											>
												<b-form-input
													id="billingLevel"
													v-model="formData.financial_risk"
													placeholder="-"
													:state="errors.length > 0 ? false : null"
													trim
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-col>
										<b-col
											cols="12"
											md="3"
											class="mb-2"
										>
											<label for="billingLevel">
												<strong>CALIBRACIÓN</strong>
											</label>
											<validation-provider
												#default="{ errors }"
												:rules="isRequired ? 'required' : ''"
												name="Calibración"
											>
												<b-form-input
													id="billingLevel"
													v-model="formData.calibration"
													placeholder="-"
													:state="errors.length > 0 ? false : null"
													trim
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-col>

										<b-col
											cols="12"
											md="3"
											class="mb-2"
										>
											<h5 class="text-info">DEUDA PENDIENTE</h5>
											<label for="debt_due">
												<strong>SBS/MICROF.</strong>
											</label>
											<validation-provider
												#default="{ errors }"
												:rules="isRequired ? 'required' : ''"
												name="Deuda Pendiente"
											>
												<b-form-input
													id="debt_due"
													v-model="formData.debt_due"
													placeholder="-"
													:state="errors.length > 0 ? false : null"
													trim
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-col>
										<b-col
											cols="12"
											md="3"
											class="mb-2"
										>
											<h5 class="text-info">DEUDA VENCIDA</h5>
											<label for="overdue_debt">
												<strong>SBS/MICROF.</strong>
											</label>
											<validation-provider
												#default="{ errors }"
												:rules="isRequired ? 'required' : ''"
												name="Deuda Vencida"
											>
												<b-form-input
													id="overdue_debt"
													v-model="formData.overdue_debt"
													placeholder="-"
													:state="errors.length > 0 ? false : null"
													trim
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-col>

										<b-col cols="12">
											<h5 class="text-info">OTRAS DEUDAS VENCIDAS</h5>
										</b-col>
										<b-col
											cols="12"
											md="3"
											class="mb-2"
										>
											<label for="protests">
												<strong>PROTESTOS</strong>
											</label>
											<validation-provider
												#default="{ errors }"
												:rules="isRequired ? 'required' : ''"
												name="Protestos"
											>
												<b-form-input
													id="protests"
													v-model="formData.protests"
													placeholder="-"
													:state="errors.length > 0 ? false : null"
													trim
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-col>
										<b-col
											cols="12"
											md="3"
											class="mb-2"
										>
											<label for="unpaid">
												<strong>DOC IMPAGO</strong>
											</label>
											<validation-provider
												#default="{ errors }"
												:rules="isRequired ? 'required' : ''"
												name="Doc Impago"
											>
												<b-form-input
													id="unpaid"
													v-model="formData.unpaid"
													placeholder="-"
													:state="errors.length > 0 ? false : null"
													trim
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-col>
										<b-col
											cols="12"
											md="3"
											class="mb-2"
										>
											<label for="labor">
												<strong>LABORAL</strong>
											</label>
											<validation-provider
												#default="{ errors }"
												:rules="isRequired ? 'required' : ''"
												name="Laboral"
											>
												<b-form-input
													id="labor"
													v-model="formData.labor"
													placeholder="-"
													:state="errors.length > 0 ? false : null"
													trim
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-col>
										<b-col
											cols="12"
											md="3"
											class="mb-2"
										>
											<label for="tributaria">
												<strong>TRIBUTARIA</strong>
											</label>
											<validation-provider
												#default="{ errors }"
												:rules="isRequired ? 'required' : ''"
												name="Tributaria"
											>
												<b-form-input
													id="tributaria"
													v-model="formData.tributaria"
													placeholder="-"
													:state="errors.length > 0 ? false : null"
													trim
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-col>
									</b-row>
								</b-card>
							</b-col>
							<b-col cols="12">
								<b-card no-body>
									<b-card-title class="text-primary">ÍNDICES INFORMATIVOS</b-card-title>
									<b-row>
										<b-col
											cols="12"
											md="3"
											class="mb-2"
										>
											<label for="rotation">
												<strong>ROTACIÓN</strong>
											</label>
											<validation-provider
												#default="{ errors }"
												:rules="isRequired ? 'required' : ''"
												name="Rotación"
											>
												<b-form-input
													id="rotation"
													v-model="formData.rotation"
													placeholder="-"
													:state="errors.length > 0 ? false : null"
													trim
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-col>
										<b-col
											cols="12"
											md="3"
											class="mb-2"
										>
											<label for="penality_number">
												<strong>PENALIDADES (CANTIDAD)</strong>
											</label>
											<validation-provider
												#default="{ errors }"
												:rules="isRequired ? 'required' : ''"
												name="Penalidades Cantidad"
											>
												<b-form-input
													id="penality_number"
													v-model="formData.penality_number"
													placeholder="-"
													:state="errors.length > 0 ? false : null"
													trim
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-col>
										<b-col
											cols="12"
											md="3"
											class="mb-2"
										>
											<label for="penality_amount">
												<strong>PENALIDADES (MONTO)</strong>
											</label>
											<validation-provider
												#default="{ errors }"
												:rules="isRequired ? 'required' : ''"
												name="Penalidades Monto"
											>
												<b-form-input
													id="penality_amount"
													v-model="formData.penality_amount"
													placeholder="-"
													:state="errors.length > 0 ? false : null"
													trim
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-col>
									</b-row>
								</b-card>
							</b-col>
						</b-row>

						<template #footer>
							<b-row>
								<b-col cols="12">
									<!-- BUTTON SUBMIT -->
									<b-button-loading
										text="EVALUAR"
										type="submit"
										:processing="processing"
									/>
								</b-col>
							</b-row>
						</template>
					</b-card>
				</b-col>
			</b-row>
		</b-form>
	</validation-observer>
</template>


<script>
import useNotifications from "@notifications"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import performanceStoreModule from "../performanceStoreModule"
import { required } from "@validations"
import vSelect from "vue-select"
import store from "@/store"
import router from "@/router"

export default {
	components: {
		ValidationProvider,
		ValidationObserver,

		BButtonLoading,

		required,
		vSelect
	},
	setup() {
		// USE TOAST
		const { toastNotification } = useNotifications()

		// REFS
		const refForm = ref(null)
		const processing = ref(false)
		const isRequired = ref(false)
		const options = ref({})

		const performanceData = ref(null)

		const blankData = {
			security: null,
			productivity: null,
			labor_risk: null,
			colpa: null,
			financial_risk: null,
			calibration: null,
			debt_due: null,
			overdue_debt: null,
			protests: null,
			unpaid: null,
			labor: null,
			tributaria: null,
			rotation: null,
			penality_number: null,
			penality_amount: null
		}

		const formData = ref(JSON.parse(JSON.stringify(blankData)))

		const PERFORMANCE_APP_STORE_MODULE_NAME = "app-performance"

		// REGISTER MODULE
		if (!store.hasModule(PERFORMANCE_APP_STORE_MODULE_NAME))
			store.registerModule(
				PERFORMANCE_APP_STORE_MODULE_NAME,
				performanceStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(PERFORMANCE_APP_STORE_MODULE_NAME))
				store.unregisterModule(PERFORMANCE_APP_STORE_MODULE_NAME)
		})

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(() => {
			fetchPerformance()
		})

		const fetchPerformance = () => {
			store
				.dispatch("app-performance/fetchPerformance", {
					id: router.currentRoute.params.id
				})
				.then((response) => {
					performanceData.value = response.data.performance
					setData(performanceData.value)
				})
				.catch((error) => {
					toastNotification(error.response.data.message, false)
				})
		}

		const formatNumber = (value) => {
			if (!value) return null
			return parseFloat(value).toFixed(2)
		}

		const setData = (data) => {
			formData.value = {
				security: formatNumber(data.security),
				productivity: formatNumber(data.productivity),
				labor_risk: formatNumber(data.labor_risk),
				colpa: formatNumber(data.colpa),
				financial_risk: formatNumber(data.financial_risk),
				calibration: formatNumber(data.calibration),
				debt_due: formatNumber(data.debt_due),
				overdue_debt: formatNumber(data.overdue_debt),
				protests: formatNumber(data.protests),
				unpaid: formatNumber(data.unpaid),
				labor: formatNumber(data.labor),
				tributaria: formatNumber(data.tributaria),
				rotation: formatNumber(data.rotation),
				penality_number: formatNumber(data.penality_number),
				penality_amount: formatNumber(data.penality_amount)
			}
		}

		const onSubmit = async () => {
			/* isRequired.value = true */

			const isValid = await refForm.value.validate()
			if (!isValid) {
				toastNotification("Complete los campos requeridos", false)
				return
			}

			processing.value = true

			const id = performanceData.value.id

			const payload = { ...formData.value }

			store
				.dispatch("app-performance/updatePerformance", { id, payload })
				.then((response) => {
					isRequired.value = false
					toastNotification(response.data.message)
					router.push({ name: "performances-list" })
				})
				.catch((err) => {
					const message =
						err.response.data.message || "Error al evaluar desemepeño"
					toastNotification(message, false)
				})
				.finally(() => (processing.value = false))
		}

		return {
			// DATA
			processing,
			formData,
			options,
			isRequired,

			// REFS
			refForm,

			// METHODS
			onSubmit
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>